import React from 'react'
import { CMSWrapper } from '../components/cmsWrapper'
import { Navigation } from '../common/navigation'
import { GlobalHelmet } from '../common/seo/globalHelmet'
import { Footer } from '../common/footer'

export default function Datenschutz() {
  return (
    <CMSWrapper>
      <GlobalHelmet title="UKE jukebox - Datenschutzerklärung" />
      <main>
        <Navigation />

        <header className="bg-blue text-center">
          <h1>Datenschutz</h1>
        </header>

        <section className="divider-right">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>
                <strong>Datenschutzerklärung</strong>
              </h2>
              <p>
                Diese Datenschutzerklärung informiert Sie über Art, Umfang und
                Zweck der Verarbeitung Ihrer personenbezogenen Daten bei der
                Nutzung der Webangebote des UKE (https://www.uke.de
                einschließlich der Subdomains). Im nachfolgenden speziell das
                Webangebot "jukebox.uke.de" und deren Unterseiten.
              </p>
              <br />

              <h3>
                <strong>
                  1. Verarbeitete Daten, Zweck und Dauer der Verarbeitung
                </strong>
              </h3>
              <p>
                <strong>
                  1.1 Verarbeitung allgemeiner Informationen beim Besuch unserer
                  Website
                </strong>
              </p>
              <p>
                Bei dem Zugriff auf die Webseite und ihren Inhalt werden
                personenbezogene Daten nur im technisch erforderlichen Umfang
                verarbeitet (insb.: IP-Adresse; Name der aufgerufenen Datei;
                Datum und Uhrzeit des Aufrufs; Br/owsertyp und Version; Ihr
                Betriebssystem; die Webpage, von welcher Sie zu uns gelangten).
                Diese Informationen sind technisch notwendig, um von Ihnen
                angeforderte Inhalte unserer Webseite einwandfrei auszuliefern.
                Sie werden regelmäßig bei dem Zugriff auf eine Webseite
                gespeichert. Ihre Daten werden insbesondere zu folgenden Zwecken
                verarbeitet:
              </p>
              <ul>
                <li>
                  Gewährleistung der Nutzung und Verbindung zum Webangebot des
                  UKE -{'>'} jukebox.uke.de,{' '}
                </li>
                <li>
                  Gewährleistung und Evaluierung der Systemsicherheit und
                  -stabilität sowie
                </li>
                <li>zu weiteren administrativen Zwecken. </li>
              </ul>

              <p>
                Die Verarbeitung Ihrer personenbezogenen Daten basiert auf
                unserem berechtigten Interesse aus den vorgenannten Zwecken zur
                Datenerhebung (Art. 6 Abs. 1 lit. f) DSGVO). Diese Daten werden
                von uns nicht bestimmten Personen zugeordnet und auch nicht mit
                anderen Datenquellen zusammengeführt. Empfänger der Daten sind
                nur das UKE und unsere Auftragsverarbeiter.
              </p>
              <p>
                Die Serverprotokolle werden täglich anonymisiert und nach 4
                Wochen gelöscht. Diese Daten werden nicht statistisch
                ausgewertet, können aber bei Bedarf für eine Fehleranalyse
                herangezogen werden.
              </p>
              <br />

              <p>
                <strong>1.2 Anmeldung zum Newsletter</strong>
              </p>
              <p>
                Wenn Sie sich im Webangebot des UKE für einen Newsletter
                anmelden, werden die dort von Ihnen angegebenen
                personenbezogenen Daten verarbeitet (jedenfalls die
                E-Mail-Adresse; ggf. auch Ihr Name). Dies ist erforderlich, um
                Ihnen regelmäßig den abonnierten Newsletter per E-Mail an Ihre
                angegebene E-Mail-Adresse zu übersenden.
              </p>
              <p>
                Um zu überprüfen, ob eine Anmeldung tatsächlich durch den
                Inhaber einer E-Mail-Adresse erfolgt, wird das
                „Double-opt-in“-Verfahren eingesetzt. Hierzu wird die Bestellung
                des Newsletters, der Versand der Bestätigungsmail und der
                Eingang der hiermit angeforderten Antwort protokolliert. Weitere
                Daten werden nicht erhoben. Die Daten werden ausschließlich für
                den Newsletterversand verwendet und nicht an Dritte
                weitergegeben.
              </p>
              <p>
                Den Newsletter sowie die Einwilligung zur Speicherung Ihrer
                persönlichen Daten sowie dessen Nutzung für die Versendung des
                Newsletters können Sie jederzeit widerrufen. In jedem Newsletter
                findet sich dazu ein entsprechender Link.
              </p>
              <p>
                Ihre o.g. personenbezogenen Daten werden für die Dauer des
                Bezugs des Newsletters verarbeitet. Nach Abmeldung werden die
                Daten unmittelbar gelöscht.
              </p>
              <br />
              <p>
                <strong>1.3 Verwendung von Kontaktformularen</strong>
              </p>
              <p>
                Im Webangebot des UKE sind auch Kontaktformulare zu
                verschiedenen Stellen des UKE enthalten, welche Ihnen die
                Kontaktaufnahme zu den von Ihnen gewünschten Einrichtungen des
                UKE erleichtern soll. Die von Ihnen im Zusammenhang mit den
                Kontaktformularen einzugebenden Daten (in der Regel Name,
                E-Mail-Adresse, Nachricht) werden unmittelbar an die Stelle
                (z.B.: Klinik oder Fachbereich innerhalb des UKE)
                weitergeleitet, an welche sich Ihre Anfrage richtet.
              </p>
              <p>
                Die Daten werden von der jeweiligen Stelle zum Zweck der
                Bearbeitung Ihrer Anfrage benötigt. Bitte beachten Sie, dass vom
                UKE auf Anfragen mit Bezug auf Patienten und Probanden in der
                Regel nicht elektronisch geantwortet wird, da eine
                unverschlüsselte E-Mail keinen hinreichend hohen Sicherheitsgrad
                für den besonderen Schutzbedarf von Gesundheitsdaten aufweist.
              </p>
              <p>
                Die Verarbeitung der jeweils übermittelten Daten erfolgt
                aufgrund Ihrer Einwilligung in die Datenverarbeitung bei
                Absendung des Kontaktformulars. Diese Einwilligung ist jederzeit
                widerruflich. Das UKE verarbeitet Ihre über ein Kontaktformular
                übermittelten personenbezogenen Daten so lange, wie dies für die
                Erledigung Ihrer Anfrage erforderlich ist. In Abhängigkeit von
                Ihrer Anfrage können sich weitere Dokumentations- und
                Aufbewahrungsfristen ergeben (z.B. 30 Jahre bei Mitteilungen zu
                Patientendaten, die Bestandteil einer Patientenakte werden).
              </p>
              <br />
              <p>
                <strong>1.4 Verwendung von Cookies</strong>
              </p>
              <p>
                Die Webangebote des UKE verwenden -wie viele andere Webseiten
                auch- sogenannte „Cookies“. Cookies sind kleine Textdateien, die
                von einem Server auf Ihre Festplatte übertragen werden. In
                diesen Cookies werden auf Ihrem Computer (etc.) Daten abgelegt.
              </p>
              <p>
                Cookies können nicht verwendet werden, um Programme zu starten
                oder Viren auf einen Computer zu übertragen. Anhand der in
                Cookies enthaltenen Informationen können wird Ihnen die
                Navigation erleichtert und die einwandfreie Anzeige der
                Webseiten ermöglicht. Cookies werden in den Webangeboten des UKE
                überdies auch eingesetzt, um statistische Auswertungen von
                Besucherzugriffen zu ermöglichen (siehe unter 1.6: Verwendung
                von Matomo).
              </p>
              <p>
                In keinem Fall werden die von uns erfassten Daten an Dritte
                weitergegeben oder ohne Ihre Einwilligung eine Verknüpfung mit
                personenbezogenen Daten hergestellt.
              </p>
              <p>
                <strong>
                  Natürlich können Sie unsere Website grundsätzlich auch ohne
                  Cookies betrachten. Bitte beachten Sie, dass einzelne
                  Funktionen unserer Website möglicherweise nicht funktionieren,
                  wenn Sie die Verwendung von Cookies deaktiviert haben.
                  Internet-Internetbr/owser sind regelmäßig so eingestellt, dass
                  sie Cookies akzeptieren. Im Allgemeinen können Sie die
                  Verwendung von Cookies jederzeit über die Einstellungen Ihres
                  Internetbr/owsers deaktivieren. Bitte verwenden Sie die
                  Hilfefunktionen Ihres Internetbr/owsers, um zu erfahren, wie
                  Sie diese Einstellungen ändern können.
                </strong>
              </p>
              <br />

              <p>
                <strong>1.6 Verwendung von Matomo</strong>
              </p>
              <p>
                Diese Website benutzt einen selbst betriebenen/gehosteten Server
                für Matomo (ehemals Piwik), eine Open-Source-Software zur
                statistischen Auswertung von Besucherzugriffen. Hierbei werden
                nutzer- bzw. personenbezogene Daten (IP-Adresse, Br/owsertyp und
                -version, Zeitpunkt der Serveranfrage, Anzahl der Besuche,
                Verweildauer auf der jeweiligen Webseite) erfasst. Die
                IP-Adresse vor der Speicherung entsprechend der Empfehlungen des
                Unabhängigen Landeszentrums für Datenschutz vor der Speicherung
                anonymisiert. Eine Verknüpfung der mittels Matomo verarbeiteten
                Daten zu Ihnen erfolgt nicht.
              </p>
              <p>
                Die Verarbeitung erfolgt auf Grundlage unserer berechtigten
                Interessen an der Analyse und Optimierung des Webangebotes des
                UKE.
              </p>
              <p>
                Matomo verwendet sog. Cookies, also Textdateien, die auf Ihrem
                Computer gespeichert werden und die eine Analyse der Benutzung
                der Website durch Sie ermöglichen. Die Cookies haben
                grundsätzlich eine Lebensdauer von einer Woche. Sie haben die
                Möglichkeit, die Installation der Cookies durch Änderung der
                Einstellung Ihrer Br/owser-Software zu verhindern (s.o.).
              </p>

              <p className="mt-4">Austragung von der Webstatistik Matomo</p>
              <div
                style={{
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  marginTop: '-15px',
                }}
              >
                <iframe
                  style={{
                    border: '0',
                    height: '260px',
                    width: '100%',
                    borderLeft: '8px solid #014a93',
                    fontFamily: 'arial !important',
                  }}
                  src="https://wwwstat.uke.de/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=&fontSize=&fontFamily="
                ></iframe>
              </div>
              <br />
              <p>
                Bei manchen Br/owsern kann es vorkommen, dass Sie sich nicht
                über die Checkbox von der Webstatistik austragen können, bitte
                nutzen Sie in diesem Fall den nachfolgenden Link. Es öffnet sich
                daraufhin ein neuer Tab, unter dem Sie das Häkchen entfernen
                können.
                <br />
                <a href="https://wwwstat.uke.de/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=&fontSize=&fontFamily=">
                  Manuelle Austragung von der Webstatistik Matomo
                </a>
              </p>

              <br />
              <br />
              <p>
                <strong>1.7 Nutzung von VIMEO (Video-Hostingplattform)</strong>
              </p>
              <p>
                Wir nutzen für die Einbindung von Videos u.a. den Anbieter
                Vimeo. Vimeo wird betrieben von Vimeo, LLC mit Hauptsitz in 555
                West 18th Street, New York, New York 10011.Dabei verwenden wir
                Plugins des Anbieters Vimeo. Wenn Sie die mit einem solchen
                Plugin versehenen Internetseiten unserer Internetpräsenz
                aufrufen, wird eine Verbindung zu den Vimeo-Servern hergestellt
                und dabei das Plugin dargestellt. Hierdurch wird an den
                Vimeo-Server übermittelt, welche unserer Internetseiten Sie
                besucht haben. Sind Sie dabei als Mitglied bei Vimeo eingeloggt
                (was für die Betrachtung einzig unseres Videos natürlich nicht
                erforderlich ist), ordnet Vimeo diese Information Ihrem
                persönlichen Benutzerkonto zu. Bei Nutzung des Plugins wie z.B.
                Anklicken des Start-Buttons eines Videos wird diese Information
                ebenfalls Ihrem Benutzerkonto zugeordnet. Sie können diese
                Zuordnung verhindern, indem Sie sich vor der Nutzung unserer
                Internetseite aus Ihrem Vimeo-Benutzerkonto abmelden und die
                entsprechenden Cookies von Vimeo löschen.Weitere Informationen
                zur Datenverarbeitung und Hinweise zum Datenschutz durch Vimeo
                finden Sie unter{' '}
                <a href="https://vimeo.com/privacy">
                  https://vimeo.com/privacy
                </a>
                .
              </p>
              <br />
              <br />
              <br />
              <h3>
                <strong>
                  2. Einsatz von Privatsphäre schützenden Techniken
                </strong>
              </h3>
              <p>
                <strong>2.1 Do not Track</strong>
              </p>
              <p>
                Sie können in Ihrem Interbr/owser „Do Not Track“ aktivieren, um
                ein Profiling im Internet teilweise zu reduzieren. Do Not Track
                (DNT; englisch für „nicht verfolgen“) signalisiert einer
                Webseite oder Webanwendung den Wunsch, dass diese über die
                Aktivitäten des Nutzers kein Nutzungsprofil erstellt.
                Selbstverständlich ist das Webangebot des UKE darauf
                ausgerichtet, entsprechende Einstellungen zu erkennen und zu
                beachten. Daher wird Matomo Ihre Aufrufe bei aktiviertem „Do Not
                Track“ so behandeln, als hätten Sie ein Opt-Out-Cookie gesetzt.
                Letztlich führen diese Techniken zu einem besseren Schutz der
                Privatsphäre des Nutzers.
              </p>
              <br />
              <p>
                <strong>2.2 SSL-Verschlüsselung</strong>
              </p>
              <p>
                Um die Sicherheit Ihrer Daten bei der Übertragung zwischen Ihrem
                Computer und dem Webangebot des UKE zu schützen, verwendet das
                UKE dem aktuellen Stand der Technik entsprechende
                Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
              </p>
              <br />

              <p>
                <strong>2.3 Eingebundene Inhalte Dritter</strong>
              </p>
              <p>
                Das UKE ist für die Verarbeitung der von Drittanbietern
                erhobenen Daten nicht verantwortlich und macht nur sehr sparsam
                von der Einbindung von Inhalten anderer Anbieter Gebr/auch.
              </p>
              <br />

              <h3>
                <strong>3. Ihre Betroffenenrechte</strong>
              </h3>
              <p>
                Ihnen stehen sog. Betroffenenrechte zu, d.h. Rechte, die Sie als
                im Einzelfall betroffene Person ausüben können. Diese Rechte
                können Sie gegenüber dem UKE gelten machen.
              </p>
              <p>
                Sie haben das <abbr>Recht auf Auskunft</abbr> über die Sie
                betreffenden gespeicherten personenbezogenen Daten nach Art. 15
                DSGVO. Wenn Sie feststellen, dass unrichtige Daten zu Ihrer
                Person verarbeitet werden, können Sie unter bestimmten
                Voraussetzungen <abbr>Berichtigung</abbr> nach Art. 16 DSGVO
                verlangen. Unvollständige Daten müssen unter Berücksichtigung
                des Zwecks der Verarbeitung <abbr>vervollständigt</abbr> werden.
                Soweit die Voraussetzungen des Art. 17 DSGVO erfüllt sind,
                insbesondere die Daten nicht mehr für den ursprünglichen Zweck
                erforderlich und die Aufbewahrungsfristen abgelaufen sind,
                können Sie von uns die <abbr>Löschung der Daten</abbr>{' '}
                verlangen. Nach Art. 18 DSGVO können Sie unter Umständen von uns
                verlangen, dass das UKE die weitere Verarbeitung der Daten{' '}
                <abbr>einschränken</abbr>. Dies bedeutet, dass Ihre Daten zwar
                nicht gelöscht, aber gekennzeichnet werden, um sie für eine
                weitere Verarbeitung zu sperren. Soweit sich die Verarbeitung
                Ihrer Patientendaten auf ein öffentliches oder berechtigtes
                Interesse im Sinne von Art. 6 Abs. 1 lit. e), f) DSGVO stützt,
                können Sie <abbr>Widerspruch nach Art. 21 DSGVO</abbr> gegen die
                Verarbeitung wegen Ihrer besonderen persönlichen Situation
                erheben.
              </p>
              <br />
              <h3>
                <strong>4. Beschwerdemöglichkeiten</strong>
              </h3>
              <p>
                Das UKE unternimmt alles, um Ihre personenbezogenen Daten
                entsprechend der gesetzlichen Vorgaben zu verarbeiten. Sollten
                Sie dennoch der Meinung sein, dass die Verarbeitung Ihrer
                personenbezogenen Daten datenschutzrechtlich unzulässig ist,
                können Sie sich gerne an den Datenschutzbeauftragten des UKE
              </p>
              <p>
                <strong>Matthias Jaster</strong>
                <br />
                Martinistraße 52
                <br />
                20246 Hamburg
                <br />
                Telefon: +49 (0) 40 7410 - 56890
                <br />
                E-Mail: m.jaster@uke.de
                <br />
              </p>
              <p>
                wenden. Darüber hinaus haben Sie die Möglichkeit, sich auch an
                die zuständige Aufsichtsbehörde mit einer Beschwerde zu wenden.
                Die für Sie zuständige Aufsichtsbehörde richtet sich nach dem
                Bundesland Ihres Wohnsitzes oder des mutmaßlichen
                Datenschutzverstoßes. Eine Liste der Aufsichtsbehörden mit
                Anschrift finden Sie unter:{' '}
                <a
                  href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                  className="tooltip decoration tooltipstered"
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html{' '}
                </a>
                .
              </p>
              <br />
              <p>
                <u>Für das UKE zuständig ist:</u>
                <strong>
                  <br />
                  Der Hamburgische Beauftragte für Datenschutz und
                  Informationsfreiheit
                </strong>
                <br />
                Kurt-Schumacher-Allee 4<br />
                20097 Hamburg
                <br />
                E-Mail: mailbox@datenschutz.hamburg.de
                <br />
                Website: https://www.datenschutz-hamburg.de
                <br />
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </CMSWrapper>
  )
}
